<template>
  <div class="page-holder">
    <div class="sign-in-holder">
      <h2>ahoy!</h2>
      <div class="field-holder">
        <label style="margin-left: 40px;">
          <span>username</span>
          <input v-model="user" style="margin-bottom: 16px;" type="username"/>
        </label>
        <label style="margin-left: 80px;">
          <span>password</span>
          <input type="password" />
        </label>
      </div>
      <button @click="handleSignIn" class="clickable">sign-in</button>
    </div>
    <p>
    Hello and welcome thank you for stopping by and taking a look at my assignment.
    Don't worry about having an account (I haven't implemented that). This form
    assumes that you type in the right information and assigns you whichever username
    you choose. <br>Happy scrolling!
    </p>
  </div>
</template>

<script>
export default {
  name: 'SignIn',
  data() {
    return {
      user: '',
    };
  },
  methods: {
    // asign user provided username to the browser
    // (used for creating posts and comments and likes)
    handleSignIn() {
      document.cookie = `testuser=${this.user}`;
      this.$store.state.user = this.user;
      document.location.assign('/');
    },
  },
};
</script>

<style scoped>
.page-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.sign-in-holder {
  position: relative;
  width: 650px;
  height: 200px;
}
p {
  margin-top: 80px;
  width: 650px;
}
h2 {
  text-align: center;
}
.field-holder {
  display: flex;
  flex-direction: column;
}
label {
  display: flex;
}
input {
  width: 250px;
  height: 25px;
  margin-left: 10px;
  background: #242222;
  border: none;
  padding: 5px 10px;
  color: #fff;
  border-radius: 4px;
}
label > span {
  line-height: 2;
}
button {
  background: var(--primary-color);
  padding: 10px;
  color: var(--black-color);

  position: absolute;
  right: 140px;
  top: 170px;

  border-radius: 4px;
  border: none;
}
a {
  position: absolute;
  left: 56px;
  bottom: -1px;
  color: var(--link-color);
}
</style>
